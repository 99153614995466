import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import $authorization from '@/lib/authorization'
import useLoader from '@/lib/composables/useLoader'
import useMembership from '@/app/composables/membership/useMembership'

export default function () {
  const membershipList = Vue.observable({
    loader: useLoader(),
    items: [],
    topupEnabledMemberships: []
  })

  membershipList.setData = data => {
    membershipList.items = data.map(cardData => {
      const membership = useMembership()
      membership.setData(cardData)
      return membership
    })
    membershipList.topupEnabledMemberships = membershipList.items.filter(membership => membership.onlinePaymentAllowedForTopups)
  }

  membershipList.load = async () => {
    await membershipList.loader.load(async () => {
      const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/memberships`)
      await fetch.promise
      membershipList.setData(fetch.data)
    })
  }

  return membershipList
}
