<template>
  <t-edit-dialog
    id="order"
    :input-fields="[
        {
          key: 'dateTime',
          type: 'date-time'
        },
        {
          key: 'tenantId',
          type: 'text'
        },
        {
          key: 'totalAmount',
          type: 'amount'
        },
        {
          key: 'items',
          label: 'label.products',
          type: 'list',
          itemKey: 'productId',
          itemText: 'description'
        }
      ]"
    :item="order"
    :read-only-for-edit="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      order: null
    }
  },

  created () {
    useEvent().registerListener(this, 'order-edit', order => {
      this.order = order
      this.$dialog.openDialog('order')
    })
  }
}
</script>
