import useSearch from '@/lib/composables/useSearch'
import useTransaction from '@/app/composables/transaction/useTransaction'
import $authorization from '@/lib/authorization'

export default function (tenantId) {
  const tenantIdFiler = tenantId ? `?tenantId=${tenantId}` : ''

  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/transactions${tenantIdFiler}`, data => {
    const transaction = useTransaction()
    transaction.setData(data)
    return transaction
  })
}
