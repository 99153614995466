import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'
import $authorization from '@/lib/authorization'

export default function () {
  const cardTemplate = {
    id: null,
    tenantId: null,
    name: null,
    disabled: null,
    statusChangeable: null
  }

  const card = useEntity(cardTemplate)

  card.save = async editCard => {
    const id = editCard.id.toLowerCase().replace(' ', '')
    const success = await $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/cards/${id}`, {
      body: {
        name: editCard.name,
        disabled: editCard.disabled
      }
    }).promise

    success && useEvent().fire('card-save')
  }

  card.checkAvailability = async id => {
    const availabilityFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/cards/${id}/check-availability`, {
      bodyType: 'json'
    })
    await availabilityFetch.promise
    if (availabilityFetch.isSuccess()) {
      const status = availabilityFetch.data.status
      if (status === 'AVAILABLE') {
        return true
      }
      if (status === 'INVALID_CARD_ID') {
        return 'validation.invalidCardId'
      }
      if (status === 'CARD_DISABLED') {
        return 'validation.cardDisabled'
      }
      if (status === 'MEMBERSHIP_DISABLED') {
        return 'validation.membershipDisabled'
      }
      return false
    }
    return false
  }

  return card
}
