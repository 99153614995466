import $authorization from '@/lib/authorization'
import useOrder from '@/app/composables/order/useOrder'
import useSearch from '@/lib/composables/useSearch'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/orders`, data => {
    const order = useOrder()
    order.setData(data)
    return order
  })
}
