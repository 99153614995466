<template>
  <div class="d-inline">
    <v-text-field
        v-if="!multiline"
        :autofocus="autofocus"
        :disabled="disabled"
        :error-messages="errorMessages"
        :label="$t(label)"
        :value="internalValue"
        @blur="handleOnBlur"
        @input="handleOnInput"
        autocomplete="off"
        ref="textField"
    />
    <v-textarea
        v-if="multiline"
        :autofocus="autofocus"
        :disabled="disabled"
        :error-messages="errorMessages"
        :label="$t(label)"
        :value="internalValue"
        @blur="handleOnBlur"
        @input="handleOnInput"
        autocomplete="off"
        ref="textField"
        :auto-grow="true"
    />
  </div>
</template>

<script>
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    numeric: {
      type: Boolean,
      default: false
    },
    alphanumeric: {
      type: Boolean,
      default: false
    },
    lowercase: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    initInternalValue () {
      this.internalValue = this.value
      if (this.value && this.availabilityHandler) {
        this.validate()
      }
    },

    handleOnInput (value) {
      let correctedValue = value
      if (correctedValue !== null && correctedValue !== undefined && this.numeric) {
        correctedValue = correctedValue.replace(/[^0-9]/g, '')
      }
      if (correctedValue !== null && correctedValue !== undefined && this.alphanumeric) {
        correctedValue = correctedValue.replace(/[^0-9a-zA-Z]/g, '')
      }
      if (correctedValue !== null && correctedValue !== undefined && this.lowercase) {
        correctedValue = correctedValue.toLowerCase()
      }

      this.internalValue = correctedValue
      this.$nextTick(() => {
        this.$refs.textField.lazyValue = correctedValue
      })

      this.emitValue()
    },

    handleOnBlur () {
      this.emitValue()
    }
  }
}
</script>
