export default {
  label: {
    accountBalance: 'Solde du compte',
    accounts: 'Comptes',
    activity: 'Activité',
    add: 'Ajouter',
    addCard: 'Ajouter carte',
    addDocument: 'Ajouter document',
    addReward: 'Ajouter récompense',
    addRewardDefinition: 'Ajouter définition de récompense',
    addUser: 'Ajouter utilisateur',
    admin: 'Administrateur',
    adminEnabled: 'Admin powered',
    administration: 'Gestion',
    administrator: 'Administrateur',
    administrators: 'Administrateurs',
    allowedForOrders: 'Autorisé pour commandes',
    allowedForTopups: 'Autorisé pour rechages',
    amount: 'Montant',
    amountEach: 'Montant chaque',
    amountExcludingVat: 'Montant hors TVA',
    amountIncludingVat: 'Montant TVA inclus',
    applicationVersion: 'Version de l\'application',
    approve: 'Approuver',
    approved: 'Approuvé',
    april: 'Avril',
    assign: 'Attribuer',
    assigned: 'Attribué',
    assignNow: 'Attribuer maintenant',
    august: 'Août',
    bancontactPayment: 'Paiement Bancontact',
    bancontactPaymentAmount: 'Payé avec Bancontact',
    basic: 'Basic',
    beginDate: 'Date de début',
    businessKey: 'Clé business',
    cancel: 'Annuler',
    cancelled: 'Annulé',
    cancelledBalance: 'Solde annulé',
    cancelMembership: 'Annuler l\'abonnement',
    cancelProcess: 'Annuler le processus',
    cardAssignments: 'Attributions de cartes',
    cardNumber: 'Numéro de carte',
    cardOrders: 'Commandes de cartes',
    cards: 'Cartes',
    cashAmount: 'Montant en espèces',
    cashier: 'Caissier',
    cashiers: 'Caissiers',
    cashPayment: 'Paiement en espèces',
    cashPaymentAmount: 'Montant payé en espèces',
    categories: 'Catégories',
    category: 'catégorie',
    close: 'Fermer',
    club: 'Club',
    clubs: 'Clubs',
    clubName: 'Nom du club',
    commonlyUsedDefinitions: 'Définitions couramment utilisées',
    companyName: 'Nom d\'entreprise',
    confirm: 'Confirmer',
    context: 'Contexte',
    continue: 'Continuer',
    corrections: 'Corrections',
    correctiveAmount: 'Montant correctif',
    count: 'Nombre',
    csvFile: 'Fichier CSV',
    currentInvoicingBeginDate: 'Date de début de facturation actuelle',
    currentInvoicingEndDate: 'Date de fin de facturation actuelle',
    currentInvoicingOrders: 'Commandes de facturation actuelle',
    currentMonth: 'Mois actuel',
    currentMonthOrders: 'Commandes du mois actuel',
    currentQuarter: 'Trimestre actuel',
    currentWeek: 'Semaine actuelle',
    currentYear: 'Année actuelle',
    custom: 'Sur mesure',
    dashboard: 'Tableau de bord',
    date: 'Date',
    dateTime: 'Date et heure',
    day: 'Jour',
    debitAmount: 'Montant du débit',
    december: 'Décembre',
    definitions: 'Définitions',
    delete: 'Effacer',
    deletedUser: 'Utilisateur effacé',
    description: 'Description',
    details: 'Détails',
    devices: 'Appareils',
    diagram: 'Diagramme',
    disableCards: 'Désactiver cartes',
    disabled: 'Désactivé',
    disabledMemberships: 'Abonnements désactivés',
    document: 'Document',
    downloadCardsCsv: 'Télécharger CSV des cartes',
    downloadCsv: 'Télécharger CSV',
    downloadDocument: 'Télécharger document',
    dutch: 'Néerlandais',
    edit: 'Éditer',
    editUser: 'Éditer utilisateur',
    email: 'Email',
    emailVerified: 'Email verifié',
    enabled: 'Actif',
    endDate: 'Date de fin',
    endTime: 'Heure de fin',
    english: 'Anglais',
    error: 'Erreur',
    execute: 'Exécuter',
    favorite: 'favori',
    february: 'Février',
    finished: 'Terminé',
    firstName: 'Prénom',
    free: 'Gratuit',
    freeOrders: 'Commandes gratuites',
    french: 'Français',
    friday: 'Vendredi',
    fromDate: 'Date de début',
    general: 'Général',
    generateQrCode: 'Générer code QR',
    grantedByUserId: 'Attribué par',
    houseNumber: 'Numéro de l\'habitation',
    id: 'Identification',
    inactive: 'Inactif',
    incident: 'Incident',
    incidents: 'Incidents',
    includeFinished: 'Inclure terminé',
    initCards: 'Initialiser cartes',
    initialized: 'Initialisé',
    initiator: 'Initiateur',
    instances: 'Instances',
    instancesWithIncident: 'Instances avec incident',
    invoice: 'Facture',
    invoices: 'Factures',
    invoicing: 'Facturation',
    invoicingSettings: 'Paramètres de facturation',
    january: 'Janvier',
    july: 'Juillet',
    june: 'Juin',
    key: 'Clé',
    language: 'Langue',
    lastName: 'Nom de famille',
    lines: 'Lignes',
    linkDevice: 'Coupler appareil',
    locale: 'Langue',
    logo: 'Logo',
    logout: 'Se déconnecter',
    manualEntry: 'Entrée manuelle',
    march: 'Mars',
    may: 'Mai',
    members: 'Membres',
    membershipCancellation: 'Annulation d\'abonnement',
    membershipCancellations: 'Annulations d\'abonnements',
    memberships: 'Abonnements',
    message: 'Message',
    minimumOrderAmount: 'Montant minimum de commande',
    minimumTopupAmount: 'Montant minimum de recharge',
    model: 'Modèle',
    modify: 'Modifier',
    modifyDocument: 'Modifier document',
    mollieApiKey: 'Clé API Mollie',
    monday: 'Lundi',
    monitorProcess: 'Reprendre le suivi',
    month: 'Mois',
    municipality: 'Commune',
    name: 'Nom',
    new: 'Nouveau',
    newDevice: 'Nouvel appareil',
    nextPlan: 'Plan suivant',
    nickname: 'Surnom',
    november: 'Novembre',
    number: 'Numéro',
    numberOfAssignments: 'Nombre d\'attributions',
    numberOfCancellations: 'Nombre d\'annulations',
    numberOfCards: 'Nombre de cartes',
    numberOfCorrections: 'Nombre de corrections',
    numberOfGrantees: 'Nombre de bénéficiaires',
    numberOfOrders: 'Nombre de commandes',
    numberOfProducts: 'Nombre de produits',
    numberOfRewards: 'Nombre de récompenses',
    numberOfTopups: 'Nombre de recharges',
    numberOfUsers: 'Nombre d\'utilisateurs',
    october: 'Octobre',
    ok: 'Ok',
    online: 'En ligne',
    onlineCashiers: 'Caissiers en ligne',
    onlineDevices: 'Appareils en ligne',
    onlineOrders: 'Commandes en ligne',
    onlinePayments: 'Paiements en ligne',
    onlineScanners: 'Scanners en ligne',
    order: 'Commande',
    orderDate: 'Date de commande',
    orderItems: 'Items de commande',
    orderReport: 'Rapport de commande',
    orderReference: 'Référence de commande',
    orders: 'Commandes',
    overallAdmin: 'Administrateur général',
    overview: 'Aperçu',
    paid: 'Payé',
    pay: 'Payer',
    payment: 'Paiement',
    paymentMethod: 'Mode de paiement',
    paymentQrCode: 'Code QR de paiement',
    period: 'Période',
    periods: 'Périodes',
    pincode: 'code PIN',
    plan: 'Plan',
    postalCode: 'Code postal',
    previousMonth: 'Mois précédent',
    previousMonthOrders: 'Commandes du mois précédent',
    previousQuarter: 'Trimestre précédent',
    previousWeek: 'Semaine précédente',
    previousYear: 'Année précédente',
    price: 'Prix',
    processedDate: 'Date de traitement',
    product: 'Produit',
    products: 'Produits',
    productSalesPerMonth: 'Ventes de produits par mois',
    productSalesPerWeekday: 'Ventes de produits par jour de la semaine',
    profile: 'Profil',
    provider: 'Fournisseur',
    quantity: 'Quantité',
    qrCodeAssignments: 'Attributions de codes QR',
    qrCodeOrders: 'Commandes de codes QR',
    qrCodes: 'Codes QR',
    reason: 'Raison',
    receivedDate: 'Date de réception',
    reference: 'Référence',
    remainingFreeOrders: 'Commandes gratuites restantes',
    renewTermsAndConditions: 'Renouveler les conditions générales',
    report: 'Rapport',
    reports: 'Rapports',
    reportType: 'Type de rapport',
    request: 'Demande',
    resend: 'Renvoyer',
    resetPin: 'Réinitialiser code PIN vers \'1111\'',
    retry: 'Réessayer',
    revenuePerMonth: 'Revenus par mois',
    revenuePerWeekday: 'Revenus par jour de la semaine',
    reward: 'Récompense',
    rewards: 'Récompenses',
    saturday: 'Samedi',
    save: 'Sauvegarder',
    scanner: 'Scanner',
    scannerId: 'Identifiant scanner',
    scanners: 'Scanners',
    search: 'Recherche',
    september: 'Septembre',
    serialNumber: 'Numéro de série',
    settings: 'Réglages',
    shortenedClubName: 'Nom du club raccourci',
    start: 'Commencer',
    startTime: 'Heurde de début',
    status: 'Statut',
    stop: 'Arrêter',
    street: 'Rue',
    subscription: 'Abonnement',
    subscriptionDate: 'Date d\'abonnement',
    subscriptionPlan_BASIC: 'Paquet de base',
    subscriptionPlan_CUSTOM: 'Sur mesure',
    subscriptionPlan_FREE: 'Gratuit',
    subscriptions: 'Abonnements',
    sum: 'Somme',
    sunday: 'Dimanche',
    suspension: 'suspension',
    tenant: 'Club',
    tenantAdmin: 'Administrateur du club',
    tenantId: 'Identifiant du club',
    tenants: 'Clubs',
    terminate: 'Terminer',
    thisWeek: 'Cette semaine',
    thisMonth: 'Ce mois',
    thisYear: 'Cette année',
    thursday: 'Jeudi',
    timezone: 'Fuseau horaire',
    toApprove: 'À approuver',
    toDate: 'Date de fin',
    today: 'Aujourd\'hui',
    toOnlinePayment: 'Vers paiement en ligne',
    toPay: 'À payer',
    topup: 'Recharge',
    topups: 'Recharges',
    topupzPayment: 'Paiement avec Topupz',
    topupzPaymentAmount: 'Montant payé avec Topupz',
    total: 'Total',
    totalAmount: 'Montant total',
    transactions: 'Transactions',
    tuesday: 'Mardi',
    type: 'Type',
    unassigned: 'Non attribué',
    unassignedCards: 'Cartes non attribué',
    unlink: 'Découpler',
    unverified: 'Non vérifié',
    user: 'Utilisateur',
    userCount: 'Nombre d\'utilisateurs',
    userGroup: 'Groupe d\'utilisateurs',
    userGroups: 'Groupes d\'utilisateurs',
    userId: 'Identifiant d\'utilisateur',
    username: 'Nom d\'utilisateur',
    users: 'Utilisateurs',
    variables: 'Variables',
    vatNumber: 'Numéro de TVA',
    version: 'Version',
    view: 'Voir',
    viewReward: 'Voir récompense',
    waitPeriod: 'Période d\'attente',
    wednesday: 'Mercredi',
    yesterday: 'Hier'
  },
  message: {
    cancelMembership: 'En annulant un abonnement, l\'utilisateur perdra le contenu de son compte. Toutes les cartes qui sont encore liées à l\'utilisateur seront découplées et optionellement désactivées.',
    card_already_added: 'La carte a déjà été ajoutée!',
    card_already_linked: 'La carte a déjà été couplée!',
    card_initialized: 'OK',
    cardOrderCsvInvalid: 'Le fichier CSV des cartes a un format non valide',
    cardOrderCsvDuplicateData: 'Le fichier CSV des cartes contient des données existantes',
    cardOrderCsvQuantityMismatch: 'Le fichier CSV des cartes ne contient pas le nombre de cartes attendu',
    cardOrderCsvDataMismatch: 'Le fichier CSV des cartes ne contient pas les cartes attendu',
    cardScanWaiting: 'Veuillez scanner une nouvelle carte',
    confirmDelete: 'Êtes-vous sûr de vouloir supprimer ces données?',
    confirmIrrevocableAction: 'Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?',
    confirmPaidSubscription: 'Vous avez choisi un plan d\'abonnement payant qui sera facturé tous les mois. Voulez-vous continuer?',
    confirmRenewTermsAndConditions: 'Tous les utilisateurs devront à nouveau accepter les conditions générales lors de leur prochaine connexion.',
    confirmUnlink: 'Êtes-vous sûr de vouloir découpler l\'appareil du client? Le client sait-il qu\'il peut y avoir une perte de données et qu\'il doit à nouveau coupler l\'appareil?',
    confirmUnlinkCard: 'Êtes-vous sûr de découpler la carte de l\'utilisateur?',
    correctionReport: 'Aperçu des corrections de commandes et de recharges',
    deviceBusy: 'L\'appareil est occupé',
    deviceLinkedToOtherTenant: 'L\'appareil est toujours couplé à un autre club',
    deviceLinkingDenied: 'La confirmation sur l\'appareil a été rejetée',
    deviceLinkingDone: 'L\'appareil est couplé',
    deviceLinkingTimeout: 'Aucune confirmation donnée sur l\'appareil',
    deviceLinkingWaiting: 'Confirmation sur l\'appareil requise',
    deviceNotAdoptable: 'L\'appareil n\'est pas en mode de couplage',
    editReward: 'Cette récompense peut toujours être modifiée car elle n\'a pas encore été attribuée. Cela se produit automatiquement après une période d\'attente de 1 heure après la création, mais vous pouvez également l\'attribuer immédiatement via l\'action \'Attribuer maintenant\'',
    initCardsDone: 'Toutes les cartes sont initialisées',
    invoicingInformationRequiredForPaidServices: 'Afin de pouvoir utiliser les services payants Topupz, les données de facturation doivent être saisies',
    linkDeviceInfo: 'Confirmez l\'utilisation de la caméra dans votre navigateur et maintenez l\'appareil avec le code QR affiché devant la caméra dès que vous voyez le flux en direct. Vous pouvez également saisir le numéro de série manuellement.',
    loadingError: 'Une erreur s\'est produite lors du chargement de l\'application',
    membershipCancellationReport: 'Aperçu des annulations d\'abonnements et des soldes de comptes annulés',
    newReward: 'En ajoutant une récompense, vous donnez le montant saisi à tous les utilisateurs sélectionnés. La récompense est attribuée après une période d\'attente de 1 heure, après laquelle vous ne pouvez plus modifier ou supprimer la récompense.',
    nextPlan: 'Le {nextPlanDate} votre abonnement passera au plan \'{nextPlan}\'',
    noAccount: 'Pour profiter de l\'expérience Topupz, vous devez d\'abord ajouter une carte. Si vous n\'en avez pas encore reçu, vous pouvez en demander un à l\'administrateur de votre club.',
    noAccounts: 'Aucun compte trouvé',
    noAdmin: 'Vous n\'êtes pas un administrateur',
    noCardAssignments: 'Aucune attribution de cartes trouvée',
    noCardOrders: 'Aucune commande trouvée',
    noCards: 'Aucune carte trouvée',
    noDataFound: 'Aucune donnée n\'a été trouvée',
    noDevices: 'Aucun appareil trouvé',
    noInvoices: 'Aucune facture trouvée',
    noOrders: 'Aucune commande trouvée',
    noProducts: 'Vous n\'avez pas encore ajouté de produits',
    noRewardDefinitions: 'Vous n\'avez pas encore créé de définitions couramment utilisées',
    noRewards: 'Aucune récompense trouvée',
    noTenants: 'Aucun club trouvé',
    noTopups: 'Aucune recharge trouvée',
    noTransactions: 'Aucune transaction trouvée',
    noUserGroups: 'Vous n\'avez pas encore créé de groupe d\'utilisateurs',
    noUsers: 'Aucun utilisateur trouvé',
    onlineOrders: 'Permets au clients de passer des commandes en ligne et de payer à l\'avance\nCes commandes sont ensuite visibles sur les caisses, où elles peuvent être traitées\nTu peux commander des codes QR que tes clients savent scanner pour arriver sur la page de commande',
    onlinePayments: 'Pour pouvoir accepter les paiements en ligne via Bancontact, Topupz peut utiliser la plateforme de paiement Mollie\nUn code QR est ensuite présenté au client à scanner dans son application Bancontact, afin de pouvoir effectuer le paiement\nVous pouvez activer les paiement en ligne séparément pour les commandes et les recharges ainsi que définir un montant minimum si vous le souhaitez\nCes paramètres nécessitent que vous ayez un compte actif chez Mollie, pour plus d\'informations veuillez consulter mollie.com',
    orderReport: 'Chiffre d\'affaires et nombre de produits vendus, au total et par produit',
    productSalesPerMonthReport: 'Nombre de produits vendus groupés par mois, au total et par produit',
    productSalesPerWeekdayReport: 'Nombre de produits vendus groupés par jour de la semaine, au total et par produit',
    qrCodeOrderCsvInvalid: 'Le fichier CSV contient des données de codes QR invalides',
    qrCodeOrderCsvDuplicateData: 'Le fichier CSV contient des codes QR existants',
    qrCodeOrderCsvQuantityMismatch: 'Le fichier CSV ne contient pas le nombre de codes QR attendu',
    qrCodeOrderCsvDataMismatch: 'Le fichier CSV ne contient pas les codes QR attendus',
    qrCodeOrderDeleteAssigned: 'La commande de code QR ne peut pas être supprimée car un code QR est toujours attribué à un club',
    registerNewDeviceInfo: 'Confirmez l\'utilisation de la caméra dans votre navigateur et tenez l\'appareil avec le code QR affiché devant la caméra dès que vous voyez le flux en direct. Vous pouvez également saisir le numéro de série manuellement',
    revenuePerMonthReport: 'Chiffres d\'affaires groupés par mois, au total et par produit',
    revenuePerWeekdayReport: 'Chiffres d\'affaires groupés par jour de la semaine, au total et par produit',
    rewardReport: 'Montant des récompenses, au total et par récompense',
    scannerBusy: 'Lecteur de carte occupé',
    scannerGone: 'Lecteur de carte inaccessible',
    scanQrCode: 'Scan le code QR avec ta caméra pour commander',
    selectUserGroup: 'Sélectionnez un groupe d\'utilisateurs',
    subscriptionPlan_BASIC: '35 € par mois pour 1000 commandes\n5 € par paquet supplémentaire de 1000 commandes, non transférable',
    subscriptionPlan_CUSTOM: 'Abonnement sur mesure selon les conditions convenues',
    subscriptionPlan_FREE: 'Limité à 10 commandes par jour\nAucune commande supplémentaire possible',
    subscriptionPlanChangeDelay: 'Vous pouvez choisir maintenant de changer de plan\nLa modification prendra effet après la fin de votre période de facturation actuelle',
    tenantSelection: 'Sélectionnez un club dont vous êtes le propriétaire ou administrateur',
    tenantSelectionEmpty: 'Vous n\'avez pas encore créé de club',
    topupReport: 'Montant total et nombre de recharges',
    unauthorized: 'Vous n\'êtes pas autorisé à utiliser cette application',
    unknown_card: 'Carte inconnue!',
    unknownDevice: 'L\'appareil est inconnu',
    userDisabledByTopupz: 'L\'utilisateur a été désactivé par Topupz',
    viewReward: 'Cette récompense ne peut plus être modifiée car elle a déjà été attribuée',
    welcome: 'Bienvenue chez Topupz!',
    welcomeMessage: 'Commencez par créer un club pour profiter de l\'expérience Topupz',
    welcomeTitle: 'Bienvenue chez Topupz pour les clubs!'
  },
  parametrized: {
    fromTo: 'De {from} à {to}'
  },
  status: {
    canceled_by_user: 'Annulé par l\'utilisateur',
    creating_online_payment: 'Le paiement en ligne est créé',
    done: 'Terminé',
    online_payment_canceled: 'Paiement en ligne annulé',
    online_payment_failed: 'Paiement en ligne échoué',
    online_payment_timeout: 'Paiement en ligne expiré',
    paid: 'Payé',
    waiting_for_online_payment: 'En attente de paiement en ligne',
    waiting_for_payment: 'En attente de paiement'
  },
  validation: {
    cardDisabled: 'La carte est désactivée',
    fieldRequired: 'Ce champ doit être rempli',
    invalidCardId: 'Identifiant de carte non valide',
    invalidUuid: 'Ce numéro d\'identification n\'est pas valide',
    invalidValue: 'Cette valeur n\'est pas valide',
    membershipDisabled: 'Abonnement désactivé',
    minValue: 'Valuer doit être au moins égale à {minValue}',
    mollieApiKeyRequired: 'Cette valeur est obligatoire si vous souhaitez pouvoir accepter des paiements en ligne',
    valueNotAvailable: 'Cette valeur n\'est pas disponible'
  }
}
