<template>
  <t-edit-dialog
    id="card-link"
    :input-fields="[
          {
            key: 'id',
            type: 'uuid',
            label: 'label.cardNumber',
            required: true,
            availabilityHandler: card.checkAvailability
          },
          {
            key: 'name',
            type: 'text'
          }
        ]"
    :item="card"
    :save-handler="card.save"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCard from '@/app/composables/card/useCard'

export default {
  data () {
    return {
      card: useCard()
    }
  },

  created () {
    useEvent().registerListener(this, 'card-link', () => {
      this.card.setData(null)
      this.$dialog.openDialog('card-link')
    })

    useEvent().registerListener(this, 'card-save', () => {
      this.$dialog.closeDialog('card-link')
    })
  }
}
</script>
