var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-process-dialog',{attrs:{"id":"topup-dialog","title":"label.topup","input-fields":[
    {
      key: 'tenantId',
      label: 'label.tenant',
      type: 'select',
      required: true,
      items: _vm.membershipList.topupEnabledMemberships,
      itemKey: 'tenantId',
      itemText: 'tenantName',
      changeHandler: _vm.handleMembershipSelectionChange
    },
    {
      key: 'amount',
      type: 'amount',
      required: true,
      minValue: function () { return _vm.membership && _vm.membership.onlinePaymentMinimumTopupAmount || 1; }
    }
  ],"statuses":[
      {
        code: 'WAITING_FOR_ONLINE_PAYMENT',
        action: true,
        actions: [
          {
            handler: _vm.handleProcessCheckout,
            label: 'label.toOnlinePayment'
          }
        ],
        image: _vm.process && _vm.process.qrCode
      },
      {
        code: 'ONLINE_PAYMENT_FAILED',
        error: true
      },
      {
        code: 'ONLINE_PAYMENT_TIMEOUT',
        error: true
      },
      {
        code: 'ONLINE_PAYMENT_CANCELED',
        error: true
      },
      {
        code: 'CANCELED_BY_USER',
        error: true
      },
      {
        code: 'DONE',
        success: true
      }
    ],"status-code":_vm.process && _vm.process.status,"start-handler":_vm.handleProcessStart,"cancel-handler":_vm.handleProcessCancel,"success-handler":function () { return _vm.event.fire('topup-done'); },"default-input-data":_vm.defaultInputData}})}
var staticRenderFns = []

export { render, staticRenderFns }