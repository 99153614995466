import $authorization from '@/lib/authorization'
import useReward from '@/app/composables/reward/useReward'
import useSearch from '@/lib/composables/useSearch'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/rewards`, data => {
    const order = useReward()
    order.setData(data)
    return order
  })
}
