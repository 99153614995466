<template>
  <t-table
    :items="rewardSearch"
    item-key="id"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true,
          },
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'reason',
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true
          },
          {
            key: 'grantedByUserId',
            sortable: true
          }
        ]"
    default-sort-property-key="dateTime"
    :default-sort-ascending="false"
    :item-filtering-handler="rewardSearch.search"
    :search="true"
    no-items-label="message.noRewards"
  />
</template>

<script>
export default {
  props: {
    rewardSearch: {
      required: true
    }
  }
}
</script>
