<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('card-link')
        }
      ]"
      :fetch="cardList.loader"
      title="label.cards">

      <card-table :card-list="cardList"/>

    </t-content-card>
  </t-page>
</template>

<script>
import useCardList from '@/app/composables/card/useCardList'
import useEvent from '@/lib/composables/useEvent'

export default {

  data () {
    return {
      cardList: useCardList(),
      event: useEvent()
    }
  },

  created () {
    this.cardList.load()
    useEvent().registerListener(this, 'card-save', () => {
      this.cardList.load()
    })
  }
}
</script>
