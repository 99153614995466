<template>
  <t-page>
    <t-content-card
      :fetch="rewardSearch.loader"
      title="label.rewards">

      <reward-table :reward-search="rewardSearch"/>

    </t-content-card>
  </t-page>
</template>

<script>
import useRewardSearch from '@/app/composables/reward/useRewardSearch'

export default {
  data () {
    return {
      rewardSearch: useRewardSearch()
    }
  }
}
</script>
