import $authorization from '@/lib/authorization'
import useSearch from '@/lib/composables/useSearch'
import useTopup from '@/app/composables/topup/useTopup'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/topups`, data => {
    const topup = useTopup()
    topup.setData(data)
    return topup
  })
}
