<template>
  <t-edit-dialog
    id="card-edit"
    :input-fields="[
          {
            key: 'tenantId',
            type: 'text',
            label: 'label.tenantId',
            readOnly: true
          },
          {
            key: 'id',
            type: 'uuid',
            label: 'label.cardNumber',
            readOnly: true
          },
          {
            key: 'name',
            type: 'text'
          },
          {
            key: 'disabled',
            type: 'switch',
            label: 'label.disabled',
            required: true,
            readOnly: !card.statusChangeable
          },
        ]"
    :item="card"
    :save-handler="card.save"
    :uuid-item="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCard from '@/app/composables/card/useCard'

export default {
  data () {
    return {
      card: useCard()
    }
  },

  created () {
    useEvent().registerListener(this, 'card-edit', card => {
      this.card = card
      this.$dialog.openDialog('card-edit')
    })

    useEvent().registerListener(this, 'card-save', () => {
      this.$dialog.closeDialog('card-edit')
    })
  }
}
</script>
