<template>
  <t-page>
    <t-content-card
      :fetch="membershipList.loader"
      title="label.memberships"
    >
      <membership-table :membership-list="membershipList" :selection-handler="handleMembershipSelection"/>
    </t-content-card>

    <t-content-card
      v-if="transactionSearch"
      :fetch="transactionSearch.loader"
      title="label.transactions"
    >
      <transaction-table :transaction-search="transactionSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useMembershipList from '@/app/composables/membership/useMembershipList'
import useTransactionSearch from '@/app/composables/transaction/useTransactionSearch'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      membershipList: useMembershipList(),
      transactionSearch: null
    }
  },

  created () {
    this.membershipList.load()
    useEvent().registerListener(this, 'topup-done', () => {
      this.membershipList.loader.refresh()
      this.transactionSearch && this.transactionSearch.refresh()
    })
  },

  beforeDestroy () {
    useEvent().unregisterListener(this)
  },

  methods: {
    handleMembershipSelection (membership) {
      this.transactionSearch = useTransactionSearch(membership.tenantId)
    }
  }
}
</script>
