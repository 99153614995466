import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import $authorization from '@/lib/authorization'
import useLoader from '@/lib/composables/useLoader'
import useCard from '@/app/composables/card/useCard'

export default function () {
  const cardList = Vue.observable({
    loader: useLoader(),
    items: []
  })

  cardList.setData = data => {
    cardList.items = data.map(cardData => {
      const card = useCard()
      card.setData(cardData)
      return card
    })
  }

  cardList.load = async () => {
    await cardList.loader.load(async () => {
      const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/cards`)
      await fetch.promise
      cardList.setData(fetch.data)
    })
  }

  return cardList
}
