export default {
  label: {
    accountBalance: 'Rekeningsaldo',
    accounts: 'Rekeningen',
    activity: 'Activiteit',
    add: 'Toevoegen',
    addCard: 'Kaart toevoegen',
    addDocument: 'Document toevoegen',
    addReward: 'Beloning toevoegen',
    addRewardDefinition: 'Definitie van een beloning toevoegen',
    addUser: 'Gebruiker toevoegen',
    admin: 'Admin',
    adminEnabled: 'Admin powered',
    administration: 'Beheer',
    administrator: 'Beheerder',
    administrators: 'Beheerders',
    allowedForOrders: 'Toegestaan voor bestellingen',
    allowedForTopups: 'Toegestaan voor opladingen',
    amount: 'Bedrag',
    amountEach: 'Bedrag elk',
    amountExcludingVat: 'Bedrag exclusief BTW',
    amountIncludingVat: 'Bedrag incusief BTW',
    applicationVersion: 'Applicatie versie',
    approve: 'Goedkeuren',
    approved: 'Goedgekeurd',
    april: 'April',
    assign: 'Toekennen',
    assigned: 'Toegekend',
    assignNow: 'Nu toekennen',
    august: 'Augustus',
    bancontactPayment: 'Betaling met Bancontact',
    bancontactPaymentAmount: 'Bedrag betaald via Bancontact',
    basic: 'Basis',
    beginDate: 'Begin datum',
    businessKey: 'Business sleutel',
    cancel: 'Annuleren',
    cancelled: 'Geannuleerd',
    cancelledBalance: 'Geannuleerd saldo',
    cancelMembership: 'Lidmaatschap annuleren',
    cancelProcess: 'Proces annuleren',
    cardAssignments: 'Toekenningen',
    cardNumber: 'Kaart Nummer',
    cardOrders: 'Bestellingen',
    cards: 'Kaarten',
    cashAmount: 'Cash bedrag',
    cashier: 'Kassa',
    cashiers: 'Kassiers',
    cashPayment: 'Betaling met cash',
    cashPaymentAmount: 'Bedrag betaald via cash',
    categories: 'Categorieën',
    category: 'Categorie',
    close: 'Sluiten',
    club: 'Club',
    clubs: 'Clubs',
    clubName: 'Club name',
    commonlyUsedDefinitions: 'Veel gebruikte definities',
    companyName: 'Bedrijfsnaam',
    confirm: 'Bevestigen',
    context: 'Context',
    continue: 'Doorgaan',
    corrections: 'Correcties',
    correctiveAmount: 'Correctief bedrag',
    count: 'Aantal',
    csvFile: 'Ontvangen CSV bestand',
    currentInvoicingBeginDate: 'Huidige facturatie begin datum',
    currentInvoicingEndDate: 'Huidige facturatie eind datum',
    currentInvoicingOrders: 'Huidige facturatie bestellingen',
    currentMonth: 'Huidige maand',
    currentMonthOrders: 'Bestellingen huidige maand',
    currentQuarter: 'Huidig kwartaal',
    currentWeek: 'Huidige week',
    currentYear: 'Huidig jaar',
    custom: 'Op maat',
    dashboard: 'Dashboard',
    date: 'Datum',
    dateTime: 'Tijdstip',
    day: 'Dag',
    debitAmount: 'Debiet bedrag',
    december: 'December',
    definitions: 'Definities',
    delete: 'Verwijderen',
    deletedUser: 'Verwijderde gebruiker',
    description: 'Beschrijving',
    details: 'Details',
    devices: 'Toestellen',
    diagram: 'Diagram',
    disableCards: 'Kaarten deactiveren',
    disabled: 'Gedeactiveerd',
    disabledMemberships: 'Gedeactiveerde lidmaatschappen',
    document: 'Document',
    downloadCardsCsv: 'Kaarten CSV downloaden',
    downloadCsv: 'CSV downloaden',
    downloadDocument: 'Document downloaden',
    dutch: 'Nederlands',
    edit: 'Bewerken',
    editUser: 'Gebruiker wijzigen',
    email: 'Email',
    emailVerified: 'Email gevalideerd',
    enabled: 'Actief',
    endDate: 'Eind datum',
    endTime: 'Eindtijd',
    english: 'Engels',
    error: 'Fout',
    execute: 'Uitvoeren',
    favorite: 'Favoriet',
    february: 'Februari',
    finished: 'Afgewerkt',
    firstName: 'Voornaam',
    free: 'Gratis',
    freeOrders: 'Gratis bestellingen',
    french: 'Frans',
    friday: 'Vrijdag',
    fromDate: 'Datum van',
    general: 'Algemeen',
    generateQrCode: 'QR code genereren',
    grantedByUserId: 'Toegekend door',
    houseNumber: 'Huisnummer',
    id: 'Id',
    inactive: 'Niet actief',
    incident: 'Incident',
    incidents: 'Incidenten',
    includeFinished: 'Inclusief afgewerkt',
    initCards: 'Kaarten initialiseren',
    initialized: 'Geïnitialiseerd',
    initiator: 'Initiatiefnemer',
    instances: 'Instanties',
    instancesWithIncident: 'Instanties met incidenten',
    invoice: 'Factuur',
    invoices: 'Facturen',
    invoicing: 'Facturatie',
    invoicingSettings: 'Facturatie instellingen',
    january: 'Januari',
    july: 'Juli',
    june: 'Juni',
    key: 'Sleutel',
    language: 'Taal',
    lastName: 'Achternaam',
    lines: 'Lijnen',
    linkDevice: 'Toestel koppelen',
    locale: 'Taal',
    logo: 'Logo',
    logout: 'Uitloggen',
    manualEntry: 'Manuele ingave',
    march: 'Maart',
    may: 'Mei',
    members: 'Leden',
    membershipCancellation: 'Lidmaatschap annulatie',
    membershipCancellations: 'Lidmaatschap annulaties',
    memberships: 'Lidmaatschappen',
    message: 'Bericht',
    minimumOrderAmount: 'Minimum bestelling bedrag',
    minimumTopupAmount: 'Minimum oplading bedrag',
    model: 'Model',
    modify: 'Wijzigen',
    modifyDocument: 'Document wijzigen',
    mollieApiKey: 'Mollie API sleutel',
    monday: 'Maandag',
    monitorProcess: 'Herneem opvolging',
    month: 'Maand',
    municipality: 'Gemeente',
    name: 'Naam',
    new: 'Nieuw',
    newDevice: 'Nieuw toestel registreren',
    nextPlan: 'Volgend plan',
    nickname: 'Benaming',
    november: 'November',
    number: 'Nummer',
    numberOfAssignments: 'Aantal toekenningen',
    numberOfCancellations: 'Aantal annulaties',
    numberOfCards: 'Aantal Kaarten',
    numberOfCorrections: 'Aantal correcties',
    numberOfGrantees: 'Aantal ontvangers',
    numberOfOrders: 'Aantal bestellingen',
    numberOfProducts: 'Aantal producten',
    numberOfRewards: 'Aantal beloningen',
    numberOfTopups: 'Aantal opladingen',
    numberOfUsers: 'Aantal gebruikers',
    october: 'Oktober',
    ok: 'Ok',
    online: 'Online',
    onlineCashiers: 'Online kassiers',
    onlineDevices: 'Online toestellen',
    onlineOrders: 'Online bestellingen',
    onlinePayments: 'Online betalingen',
    onlineScanners: 'Online scanners',
    order: 'Bestelling',
    orderDate: 'Besteldatum',
    orderItems: 'Bestelling items',
    orderReport: 'Bestelrapport',
    orderReference: 'Bestelreferentie',
    orders: 'Bestellingen',
    overallAdmin: 'Algemene admin',
    overview: 'Overzicht',
    paid: 'Betaald',
    pay: 'Betaal',
    payment: 'Betaling',
    paymentMethod: 'Betalingsmethode',
    paymentQrCode: 'Betaling QR code',
    period: 'Periode',
    periods: 'Periodes',
    pincode: 'Pincode',
    plan: 'Plan',
    postalCode: 'Postcode',
    previousMonth: 'Vorige maand',
    previousMonthOrders: 'Bestellingen vorige maand',
    previousQuarter: 'Vorig kwartaal',
    previousWeek: 'Vorige week',
    previousYear: 'Vorig jaar',
    price: 'Prijs',
    processedDate: 'Verwerkingsdatum',
    product: 'Product',
    products: 'Producten',
    productSalesPerMonth: 'Productverkoop per maand',
    productSalesPerWeekday: 'Productverkoop per weekdag',
    profile: 'Profiel',
    provider: 'Leverancier',
    quantity: 'Hoeveelheid',
    qrCodeAssignments: 'QR code toekenningen',
    qrCodeOrders: 'QR code bestellingen',
    qrCodes: 'QR codes',
    reason: 'Reden',
    receivedDate: 'Ontvangstdatum',
    reference: 'Referentie',
    remainingFreeOrders: 'Resterende gratis bestellingen',
    renewTermsAndConditions: 'Algemene voorwaarden vernieuwen',
    report: 'Rapport',
    reports: 'Rapporten',
    reportType: 'Rapport type',
    request: 'Aanvragen',
    resend: 'Opnieuw versturen',
    resetPin: 'Reset pincode naar \'1111\'',
    retry: 'Opnieuw proberen',
    revenuePerMonth: 'Omzet per maand',
    revenuePerWeekday: 'Omzet per weekdag',
    reward: 'Beloning',
    rewards: 'Beloningen',
    saturday: 'Zaterdag',
    save: 'Opslaan',
    scanner: 'Scanner',
    scannerId: 'Kaartlezer Id',
    scanners: 'Scanners',
    search: 'Zoeken',
    september: 'September',
    serialNumber: 'Serienummer',
    settings: 'Instellingen',
    shortenedClubName: 'Verkorte clubnaam',
    start: 'Start',
    startTime: 'Starttijd',
    status: 'Status',
    stop: 'Stoppen',
    street: 'Straat',
    subscription: 'Abonnement',
    subscriptionDate: 'Inschrijvingsdatum',
    subscriptionPlan_BASIC: 'Basispakket',
    subscriptionPlan_CUSTOM: 'Op maat',
    subscriptionPlan_FREE: 'Gratis',
    subscriptions: 'Abonnementen',
    sum: 'Som',
    sunday: 'Zondag',
    suspension: 'Onderbreking',
    tenant: 'Club',
    tenantAdmin: 'Club beheerder',
    tenantId: 'Club ID',
    tenants: 'Clubs',
    terminate: 'Terminate',
    thisWeek: 'Deze week',
    thisMonth: 'Deze maand',
    thisYear: 'Dit jaar',
    thursday: 'Donderdag',
    timezone: 'Tijdzone',
    toApprove: 'Goed te keuren',
    toDate: 'Datum tot',
    today: 'Vandaag',
    toOnlinePayment: 'Naar online betaling',
    toPay: 'Te betalen',
    topup: 'Oplading',
    topups: 'Opladingen',
    topupzPayment: 'Betaling met Topupz',
    topupzPaymentAmount: 'Bedrag betaald via Topupz',
    total: 'Totaal',
    totalAmount: 'Totaal bedrag',
    transactions: 'Transacties',
    tuesday: 'Dinsdag',
    type: 'Type',
    unassigned: 'Niet toegekend',
    unassignedCards: 'Niet toegekende kaarten',
    unlink: 'Loskoppelen',
    unverified: 'Niet geverifieerd',
    user: 'Gebruiker',
    userCount: 'Aantal gebruikers',
    userGroup: 'Gebruikersgroep',
    userGroups: 'Gebruikersgroepen',
    userId: 'Gebruiker ID',
    username: 'Gebruikersnaam',
    users: 'Gebruikers',
    variables: 'Variabelen',
    vatNumber: 'BTW nummer',
    version: 'Versie',
    view: 'Bekijken',
    viewReward: 'Beloning bekijken',
    waitPeriod: 'Wachtperiode',
    wednesday: 'Woensdag',
    yesterday: 'Gisteren'
  },
  message: {
    cancelMembership: 'Door een lidmaatschap te annuleren zal de gebruiker de inhoud van zijn rekening verliezen. Alle kaarten die nog steeds aan de gebruiker verbonden zijn zullen ontbonden en optioneel gedeactiveerd worden.',
    card_already_added: 'Kaart is al aangeboden geweest!',
    card_already_linked: 'Kaart is reeds gekoppeld!',
    card_initialized: 'OK',
    cardOrderCsvInvalid: 'De kaarten CSV heeft een ongeldig formaat',
    cardOrderCsvDuplicateData: 'De kaarten CSV bevat reeds bestaande gegevens',
    cardOrderCsvQuantityMismatch: 'De kaarten CSV heeft niet de verwachte hoeveelheid',
    cardOrderCsvDataMismatch: 'De kaarten CSV bevat niet de verwachten kaarten',
    cardScanWaiting: 'Gelieve een nieuwe kaart te scannen',
    confirmDelete: 'Bent u zeker dat u deze gegevens wilt verwijderen?',
    confirmIrrevocableAction: 'Deze actie kan niet meer ongedaan worden. Bent u zeker dat u wilt doorgaan?',
    confirmPaidSubscription: 'U heelf een betalend abonnementsplan gekozen die om de maand gefactureerd zal worden. Wilt u veder gaan?',
    confirmRenewTermsAndConditions: 'Alle gebruikers zullen de alemene voorwaarden opnieuw moeten aanvaarden de volgende keer dat ze aanmelden.',
    confirmUnlink: 'Bent u zeker dat u het toestel wil loskopppelen van deze klant? Is de klant op de hoogte dat er mogelijk dataverlies kan zijn en dat hij/zij het toestel opnieuw moet koppelen?',
    confirmUnlinkCard: 'Bent u zeker om deze kaart van deze gebruiker los te koppelen?',
    correctionReport: 'Overzicht van de correcties van bestelling en opladingen',
    deviceBusy: 'Toestel is bezig',
    deviceLinkedToOtherTenant: 'Toestel is nog gelinkt aan een andere club',
    deviceLinkingDenied: 'Bevestiging op het toestel is afgewezen',
    deviceLinkingDone: 'Het toestel is gekoppeld',
    deviceLinkingTimeout: 'Geen bevestiging op toestel gegeven',
    deviceLinkingWaiting: 'Bevestiging op het toestel nodig',
    deviceNotAdoptable: 'Toestel is niet in koppelmodus',
    editReward: 'Deze beloning kan nog gewijzigd worden aangezien ze nog niet toegekend is. Dit gebeurt automatisch na een wachtperiode van 1 uur na het aanmaken, maar u kan deze ook onmiddelijk toekennen via de actie \'Nu toekennen\'',
    initCardsDone: 'Alle kaarten zijn geïnitialiseerd',
    invoicingInformationRequiredForPaidServices: 'Om gebruik te kunnen maken van betalende Topupz diensten dienen de facturatie gegevens ingevuld te zijn',
    linkDeviceInfo: 'Bevestig het cameragebruik in uw browser en hou het toestel met de getoonde QR-code voor de camera van zodra u de livefeed ziet. U kunt ook het serienummer manueel ingeven',
    loadingError: 'Er is een fout opgetreden bij het laden van de applicatie',
    membershipCancellationReport: 'Overzicht van de annulaties van lidmaatschappen en de geannuleerde rekeningsalo\'s',
    newReward: 'Door een beloning toe te voegen geeft u het ingegeven bedrag aan alle geselecteerde gebruikers. Het toekennen van de beloning gebeurt na een wachtperiode van 1 uur, hierna kan u de beloning niet meer aanpassen of verwijderen',
    nextPlan: 'Op {nextPlanDate} zal uw abonnement overschakelen naar het plan \'{nextPlan}\'',
    noAccount: 'Om van de Topupz ervaring te kunnen genieten, dien je eerst een kaart toe te voegen. Als je er nog geen ontvangen hebt, kan je dit bij de beheerder van je club aanvragen.',
    noAccounts: 'Geen rekeningen gevonden',
    noAdmin: 'U bent geen beheerder',
    noCardAssignments: 'Geen toekenningen gevonden',
    noCardOrders: 'Geen bestellingen gevonden',
    noCards: 'Geen kaarten gevonden',
    noDataFound: 'Er zijn geen gegevens gevonden',
    noDevices: 'Geen toestellen gevonden',
    noInvoices: 'U heeft geen facturen',
    noOrders: 'Geen bestellingen gevonden',
    noProducts: 'U heeft nog geen producten toegevoegd',
    noRewardDefinitions: 'U heeft nog geen veelgebuikte definities aangemaakt',
    noRewards: 'Geen beloningen gevonden',
    noTenants: 'Geen clubs gevonden',
    noTopups: 'Geen opladingen gevonden',
    noTransactions: 'Geen transacties gevonden',
    noUserGroups: 'U heeft nog geen gebruikersgroepen aangemaakt',
    noUsers: 'Geen gebruikers gevonden',
    onlineOrders: 'Laat je klanten toe om online bestellingen te plaaten en op voorhand te betalen\nDeze bestellingen zijn nadien zichtbaar op de kassa\'s, waar ze verder behandeld kunnen worden\nJe kan QR codes bestellen die je klanten kunnen scannen om op de bestelpagina te geraken',
    onlinePayments: 'Om online betalingen via Bancontact te kunnen aanvaarden kan Topupz gebruiken maken van het betalingsplatform Mollie\nEen QR code wordt dan aan de klant getoond om in zijn Bancontact applicatie te scannen, en zo de betaling te kunnen uitvoeren\nU kunt online betaling apart activeren voor bestellingen en opladingen en indien gewenst een minimum bedrag instellen\nDeze instellingen vereisen dat u een actieve rekening bij Mollie hebt, voor meer informatie kunt u mollie.com raadplagen',
    orderReport: 'Omzet en aantal verkochte producten, in totaal en per product',
    productSalesPerMonthReport: 'Aantal verkochte producten gegroepeerd per maand, in totaal en per product',
    productSalesPerWeekdayReport: 'Aantal verkochte producten gegroepeerd per dag, in totaal en per product',
    qrCodeOrderCsvInvalid: 'De CSV bevat ongelige QR code gegevens',
    qrCodeOrderCsvDuplicateData: 'De CSV bevat reeds bestaande QR codes',
    qrCodeOrderCsvQuantityMismatch: 'De CSV bevat niet het verwachte aantal QR codes',
    qrCodeOrderCsvDataMismatch: 'De CSV bevat niet de verwachten QR codes',
    qrCodeOrderDeleteAssigned: 'De QR code bestelling kan niet worden verwijderd omdat er nog een QR code toegewezen is aan een club',
    registerNewDeviceInfo: 'Bevestig het cameragebruik in je browser en hou het toestel met de getoonde QR-code voor de camera van zodra je de livefeed ziet. Je kan ook het serienummer manueel ingeven',
    revenuePerMonthReport: 'Omzet gegroepeerd per maand, in totaal en per product',
    revenuePerWeekdayReport: 'Omzet gegroepeerd per dag, in totaal en per product',
    rewardReport: 'Bedrag van beloningen, in totaal en per toekenning',
    scannerBusy: 'Kaartlezer is bezig',
    scannerGone: 'Kaartlezer niet bereikbaar',
    scanQrCode: 'Scan de QR code met je camera om te bestellen',
    selectUserGroup: 'Selecteer een gebruikersgroep',
    subscriptionPlan_BASIC: '€35 per maand voor 1000 bestellingen\n€5 per bijkomend pakket van 1000 bestellingen, niet overdraagbaar',
    subscriptionPlan_CUSTOM: 'Abonnement op maat volgends de afgesproken voorwaarden',
    subscriptionPlan_FREE: 'Beperkt tot 10 bestellingen per dag\nGeen bijkomende bestellingen mogelijk',
    subscriptionPlanChangeDelay: 'U kunt nu kiezen om van abonnement te veranderen\nDe wijziging zal doorgaan na verloop van uw huidige facturatie periode',
    tenantSelection: 'Selecteer een club waar u eigenaar of beheerder van bent',
    tenantSelectionEmpty: 'U heeft nog geen clubs aangemaakt',
    topupReport: 'Totaal bedrag en aantal topups',
    unauthorized: 'U bent niet toegelaten om deze applicatie te gebruiken',
    unknown_card: 'Onbekende kaart!',
    unknownDevice: 'Toestel is niet gekend',
    userDisabledByTopupz: 'De gebruiker is door Topupz gedeactiveerd',
    viewReward: 'Deze beloning kan niet meer aangepast worden aangezien ze reeds toegekend is',
    welcome: 'Welkom bij Topupz!',
    welcomeMessage: 'Begin door een club aan te maken om te kunnen genieten van Topupz',
    welcomeTitle: 'Welkom bij Topupz voor clubs'
  },
  parametrized: {
    fromTo: 'Van {from} tot {to}'
  },
  status: {
    canceled_by_user: 'Geannuleerd door gebruiker',
    creating_online_payment: 'Online betaling wordt aangemaakt',
    done: 'Uitgevoerd',
    online_payment_canceled: 'Online betaling geannuleerd',
    online_payment_failed: 'Online betaling gefaald',
    online_payment_timeout: 'Online betaling verlopen',
    paid: 'Betaald',
    waiting_for_online_payment: 'Wachten op online betaling',
    waiting_for_payment: 'Wachten op betaling'
  },
  validation: {
    cardDisabled: 'Kaart is gedeactiveerd',
    fieldRequired: 'Dit veld is verplicht',
    invalidCardId: 'Ongeldige kaart ID',
    invalidUuid: 'Dit is geen geldig identificatie nummer',
    invalidValue: 'Deze waarde is ongeldig',
    membershipDisabled: 'Lidmaatschap is gedeactiveerd',
    minValue: 'Waarde moet minstens gelijk zijn aan {minValue}',
    mollieApiKeyRequired: 'Deze waarde is verplicht als u online betalingen wilt kunnen aanvaarden',
    valueNotAvailable: 'Deze waarde is niet beschikbaar'
  }
}
