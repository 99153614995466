<template>
  <t-page>
    <t-content-card
      :fetch="orderSearch.loader"
      title="label.orders"
    >
      <order-table :order-search="orderSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useOrderSearch from '@/app/composables/order/useOrderSearch'

export default {
  data () {
    return {
      orderSearch: useOrderSearch()
    }
  }
}
</script>
