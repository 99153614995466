import Vue from 'vue'
import VueBase from './lib/vue-base'
import $authorization from './lib/authorization'

import App from './app/App'
import CardEditDialog from './app/components/card/CardEditDialog'
import CardLinkDialog from './app/components/card/CardLinkDialog'
import CardPage from './app/components/card/CardPage'
import CardTable from './app/components/card/CardTable'
import MembershipPage from './app/components/membership/MembershipPage'
import MembershipTable from './app/components/membership/MembershipTable'
import OrderDialog from './app/components/order/OrderDialog'
import OrderPage from './app/components/order/OrderPage'
import OrderTable from './app/components/order/OrderTable'
import RewardPage from './app/components/reward/RewardPage'
import RewardTable from './app/components/reward/RewardTable'
import TopupDialog from './app/components/topup/TopupDialog'
import TopupPage from './app/components/topup/TopupPage'
import TopupTable from './app/components/topup/TopupTable'
import TransactionTable from './app/components/transaction/TransactionTable'
import WelcomeDialog from './app/components/welcome/WelcomeDialog'

$authorization.keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL
$authorization.realm = 'topupz'
$authorization.keycloakClientId = 'user-webapp'
$authorization.keycloakAuthorizationHandler = (user) => {
  return user.roles.includes('user')
}

Vue.mixin({
  beforeCreate () {
  }
})

const components = {
  CardEditDialog,
  CardLinkDialog,
  CardTable,
  MembershipTable,
  OrderDialog,
  OrderTable,
  RewardTable,
  TopupDialog,
  TopupTable,
  TransactionTable,
  WelcomeDialog
}
Object.keys(components).forEach(name => {
  Vue.component(name, components[name])
})

$authorization.authenticateUser().then(() => {
  VueBase.init({
    app: App,
    routes: [
      {
        path: '/memberships',
        name: 'memberships',
        component: MembershipPage
      },
      {
        path: '/cards',
        name: 'cards',
        component: CardPage
      },
      {
        path: '/topups',
        name: 'topups',
        component: TopupPage
      },
      {
        path: '/orders',
        name: 'orders',
        component: OrderPage
      },
      {
        path: '/rewards',
        name: 'rewards',
        component: RewardPage
      }
    ]
  })
})
