<template>
  <t-table
    ref="transactionsTable"
    :default-sort-ascending="false"
    :item-filtering-handler="transactionSearch.search"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'context',
            type: 'icon',
            icon: transaction => {
              switch (transaction.context) {
                case 'ORDER': return {
                  image: 'mdi-shopping',
                  tooltip: 'label.order'
                }
                case 'TOPUP': return {
                  image: 'mdi-credit-card-plus',
                  tooltip: 'label.topup'
                }
                case 'REWARD': return {
                  image: 'mdi-gift',
                  tooltip: 'label.reward'
                }
                case 'MEMBERSHIP_CANCELLATION': return {
                  image: 'mdi-account-remove',
                  tooltip: 'label.membershipCancellation'
                }
              }
            },
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true,
            icon: transaction => transaction.amount > 0 ? { image: 'mdi-arrow-up-bold', color: 'success' } : { image: 'mdi-arrow-down-bold', color: 'error' }
          },
          {
            key: 'cardId',
            header: 'label.cardNumber',
            type: 'uuid',
            sortable: true
          },
        ]"
    :items="transactionSearch"
    :search="true"
    default-sort-property-key="dateTime"
    item-key="id"
    no-items-label="message.noTransactions"
  />
</template>

<script>
export default {
  props: {
    transactionSearch: {
      required: true
    }
  },

  watch: {
    transactionSearch () {
      if (this.$refs.transactionsTable) {
        this.$nextTick(() => {
          this.$refs.transactionsTable.notifyItemFilteringHandler()
        })
      }
    }
  }
}
</script>
