<template>
  <t-page>
    <t-content-card
      title="label.topups"
      :fetch="topupSearch.loader"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('topup'),
          disabledHandler: () => membershipList.topupEnabledMemberships.length === 0
        }
      ]"
    >
      <topup-table :topup-search="topupSearch" />
    </t-content-card>
  </t-page>
</template>

<script>
import useTopupSearch from '@/app/composables/topup/useTopupSearch'
import useMembershipList from '@/app/composables/membership/useMembershipList'
import useEvent from '@/lib/composables/useEvent'

export default {

  data () {
    return {
      topupSearch: useTopupSearch(),
      membershipList: useMembershipList(),
      event: useEvent()
    }
  },

  async created () {
    await this.membershipList.load()
    this.event.registerListener(this, 'topup-done', () => {
      this.topupSearch.refresh()
    })
  },

  beforeDestroy () {
    this.event.unregisterListener(this)
  }
}
</script>
