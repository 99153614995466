<template>
  <t-process-dialog
    id="topup-dialog"
    title="label.topup"
    :input-fields="[
      {
        key: 'tenantId',
        label: 'label.tenant',
        type: 'select',
        required: true,
        items: membershipList.topupEnabledMemberships,
        itemKey: 'tenantId',
        itemText: 'tenantName',
        changeHandler: handleMembershipSelectionChange
      },
      {
        key: 'amount',
        type: 'amount',
        required: true,
        minValue: () => membership && membership.onlinePaymentMinimumTopupAmount || 1
      }
    ]"
    :statuses="[
        {
          code: 'WAITING_FOR_ONLINE_PAYMENT',
          action: true,
          actions: [
            {
              handler: handleProcessCheckout,
              label: 'label.toOnlinePayment'
            }
          ],
          image: process && process.qrCode
        },
        {
          code: 'ONLINE_PAYMENT_FAILED',
          error: true
        },
        {
          code: 'ONLINE_PAYMENT_TIMEOUT',
          error: true
        },
        {
          code: 'ONLINE_PAYMENT_CANCELED',
          error: true
        },
        {
          code: 'CANCELED_BY_USER',
          error: true
        },
        {
          code: 'DONE',
          success: true
        }
      ]"
    :status-code="process && process.status"
    :start-handler="handleProcessStart"
    :cancel-handler="handleProcessCancel"
    :success-handler="() => event.fire('topup-done')"
    :default-input-data="defaultInputData"
  />
</template>

<script>
import { v4 as uuid } from 'uuid'
import useMembershipList from '@/app/composables/membership/useMembershipList'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      event: useEvent(),
      membershipList: useMembershipList(),
      membership: null,
      processId: null,
      processSse: null,
      process: null,
      defaultInputData: {
        tenantId: null,
        amount: null
      }
    }
  },

  beforeDestroy () {
    this.processSse && this.processSse.close()
  },

  async created () {
    if (this.$route.query.topupTenantId && this.$route.query.topupProcessId) {
      await this.membershipList.load()
      this.membership = this.membershipList.items.find(membership => membership.tenantId === this.$route.query.topupTenantId)
      this.processId = this.$route.query.topupProcessId
      this.startProcessSse()
      this.$dialog.openDialog('topup-dialog')
    }

    useEvent().registerListener(this, 'topup', async membership => {
      this.processSse && this.processSse.close()
      this.processId = null
      this.processSse = null
      this.process = null

      await this.membershipList.load()
      this.membership = membership || this.membershipList.topupEnabledMemberships[0]

      console.log('MEMBERSHIP', this.membership)

      this.defaultInputData = {
        tenantId: this.membership.tenantId,
        amount: this.membership.onlinePaymentMinimumTopupAmount
      }

      this.$dialog.openDialog('topup-dialog')
    })
  },

  methods: {
    async handleProcessStart (inputData) {
      if (!inputData.tenantId || !inputData.amount) {
        this.processSse && this.processSse.close()
        this.processId = null
        this.processSse = null
        this.process = null
        return
      }

      this.processId = uuid()
      const startProcessFetch = this.$fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/users/${this.$authorization.user.userId}/tenants/${this.membership.tenantId}/processes/topup/instances/${this.processId}`, {
        body: {
          amount: inputData.amount,
          paymentMethod: 'BANCONTACT',
          redirectUrl: `${window.location.href.split('?')[0]}?topupTenantId=${this.membership.tenantId}&topupProcessId=${this.processId}`
        }
      })
      await startProcessFetch.promise

      if (startProcessFetch.isSuccess()) {
        this.startProcessSse()
      }
    },

    handleProcessCancel () {
      this.$fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/users/${this.$authorization.user.userId}/tenants/${this.membership.tenantId}/processes/topup/instances/${this.processId}/cancel`)
    },

    handleProcessCheckout () {
      window.location = this.process.checkoutUrl
    },

    startProcessSse () {
      this.processSse && this.processSse.close()

      const vm = this
      this.processSse = this.$sse.sse(`${process.env.VUE_APP_TOPUPZ_URL}/users/${this.$authorization.user.userId}/tenants/${this.membership.tenantId}/processes/topup/instances/${this.processId}/events`, {
        onMessage (process) {
          console.dir(process)
          vm.process = process
        }
      })
    },

    handleMembershipSelectionChange (tenantId) {
      this.membership = this.membershipList.topupEnabledMemberships.find(membership => membership.tenantId === tenantId)
      this.defaultInputData = {
        tenantId: this.membership.tenantId,
        amount: this.membership.onlinePaymentMinimumTopupAmount
      }
    }
  }
}
</script>
