var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"items":_vm.rewardSearch,"item-key":"id","item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true,
        },
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'reason',
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        },
        {
          key: 'grantedByUserId',
          sortable: true
        }
      ],"default-sort-property-key":"dateTime","default-sort-ascending":false,"item-filtering-handler":_vm.rewardSearch.search,"search":true,"no-items-label":"message.noRewards"}})}
var staticRenderFns = []

export { render, staticRenderFns }