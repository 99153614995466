import Vue from 'vue'

const $dialog = Vue.observable({
  openDialogIds: [],
  dialogDatas: {},
  dialogCloseHandlers: {},

  openDialog (dialogId, dialogData = null, closeHandler = null) {
    if (!$dialog.openDialogIds.includes(dialogId)) {
      $dialog.openDialogIds.push(dialogId)
      if (dialogData) {
        $dialog.dialogDatas[dialogId] = dialogData
      }
      if (closeHandler) {
        $dialog.dialogCloseHandlers[dialogId] = closeHandler
      }
    }
  },

  closeDialog (dialogId, closeHandlerOrData) {
    $dialog.openDialogIds.splice($dialog.openDialogIds.findIndex(openDialogId => openDialogId === dialogId), 1)
    if ($dialog.dialogCloseHandlers[dialogId]) {
      $dialog.dialogCloseHandlers[dialogId](closeHandlerOrData)
    } else if (closeHandlerOrData && typeof closeHandlerOrData === 'function') {
      closeHandlerOrData()
    }
    delete $dialog.dialogDatas[dialogId]
    delete $dialog.dialogCloseHandlers[dialogId]
  },

  isDialogOpen (dialogId) {
    return $dialog.openDialogIds.includes(dialogId)
  },

  isDialogOnTop (dialogId) {
    return $dialog.openDialogIds.length && $dialog.openDialogIds[$dialog.openDialogIds.length - 1] === dialogId
  },

  getDialogData (dialogId) {
    return $dialog.dialogDatas[dialogId] || null
  }
})

export default $dialog
