<template>
  <t-table
    :items="cardList.items"
    item-key="id"
    :item-properties="[
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'id',
            header: 'label.cardNumber',
            type: 'uuid',
            sortable: true
          },
          {
            key: 'name',
            header: 'label.name',
            sortable: true
          },
          {
            key: 'disabled',
            type: 'icon',
            icon: card => card.disabled ? { image: 'mdi-credit-card-off', color: 'error' } : null,
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: card => event.fire('card-edit', card)
          }
        ]"
    default-sort-property-key="tenantId"
    no-items-label="message.noCards"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    cardList: {
      required: true
    }
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
