import useEntity from '@/lib/composables/useEntity'

export default function () {
  const topupTemplate = {
    id: null,
    tenantId: null,
    dateTime: null,
    amount: null,
    paymentMethod: null,
    initiator: null
  }

  return useEntity(topupTemplate)
}
