import useEntity from '@/lib/composables/useEntity'

export default function () {
  const rewardTemplate = {
    id: null,
    tenantId: null,
    dateTime: null,
    reason: null,
    amount: null,
    grantedByUserId: null
  }

  return useEntity(rewardTemplate)
}
