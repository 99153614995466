<template>
  <t-table
    :items="orderSearch"
    item-key="id"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'totalAmount',
            type: 'amount',
            sortable: true
          },
          {
            key: 'numberOfProducts',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.details',
            icon: 'mdi-eye',
            iconClickHandler: order => event.fire('order-edit', order)
          }
        ]"
    :search="true"
    :item-filtering-handler="orderSearch.search"
    default-sort-property-key="dateTime"
    :default-sort-ascending="false"
    no-items-label="message.noOrders"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    orderSearch: {
      required: true
    }
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
