<template>
  <div>
    <t-web-app
      :menu-items="[
          {
            icon: 'mdi-account-group',
            label: 'label.memberships',
            route: 'memberships'
          },
          {
            icon: 'mdi-cards',
            label: 'label.cards',
            route: 'cards'
          },
          {
            icon: 'mdi-credit-card-plus',
            label: 'label.topups',
            route: 'topups'
          },
          {
            icon: 'mdi-shopping',
            label: 'label.orders',
            route: 'orders'
          },
          {
            icon: 'mdi-gift',
            label: 'label.rewards',
            route: 'rewards'
          }
       ]"
      title="label.user"
    />

    <card-edit-dialog/>
    <card-link-dialog/>
    <order-dialog/>
    <topup-dialog/>
    <welcome-dialog/>
  </div>
</template>

<script>
export default {}
</script>

<style>
/*@Benny: are you ok with the colors? Is same as in Cashier app. Should this be set in vue base instead?*/
div.v-dialog:not(.v-dialog--fullscreen),
div.v-dialog:not(.v-dialog--fullscreen) div.v-sheet,
div.v-dialog:not(.v-dialog--fullscreen) div.v-card,
div.v-dialog:not(.v-dialog--fullscreen) div.v-card > div.v-list {
  background-color: #424242 !important;
}
</style>
