var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"card-link","input-fields":[
        {
          key: 'id',
          type: 'uuid',
          label: 'label.cardNumber',
          required: true,
          availabilityHandler: _vm.card.checkAvailability
        },
        {
          key: 'name',
          type: 'text'
        }
      ],"item":_vm.card,"save-handler":_vm.card.save}})}
var staticRenderFns = []

export { render, staticRenderFns }