<template>
  <v-dialog :value="$dialog.isDialogOpen('welcome')" max-width="600" persistent>
    <v-card>
      <v-card-text>
        <div class="pt-4 pb-4 display-1 primary--text">{{ $t('message.welcome') }}</div>
        <div class="subtitle-1">{{ $t('message.noAccount') }}</div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"/>
        <t-button
          text
          label="label.close"
          @click="$dialog.closeDialog('welcome')"
        />
        <t-button
          text
          label="label.addCard"
          @click="handleAddCard" primary
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCardList from '@/app/composables/card/useCardList'
import useMembershipList from '@/app/composables/membership/useMembershipList'

export default {
  async created () {
    const cardList = useCardList()
    const membershipList = useMembershipList()

    await cardList.load()
    await membershipList.load()

    if (cardList.items.length === 0 && membershipList.items.length === 0) {
      this.$dialog.openDialog('welcome')
    }
  },

  methods: {
    handleAddCard () {
      this.$dialog.closeDialog('welcome')
      if (this.$route.path !== '/cards') {
        this.$router.push('/cards')
      }
      useEvent().fire('card-link')
    }
  }
}
</script>
