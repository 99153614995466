import useEntity from '@/lib/composables/useEntity'

export default function () {
  const membershipTemplate = {
    status: null,
    tenantId: null,
    tenantName: null,
    numberOfCards: null,
    accountBalance: null,
    onlinePaymentAllowedForTopups: null,
    onlinePaymentMinimumTopupAmount: null
  }

  return useEntity(membershipTemplate)
}
