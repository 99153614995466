import useEntity from '@/lib/composables/useEntity'

export default function () {
  const transactionTemplate = {
    id: null,
    context: null,
    dateTime: null,
    amount: null,
    cardId: null
  }

  return useEntity(transactionTemplate)
}
