var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.topupSearch.search,"item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        },
        {
          key: 'paymentMethod',
          type: 'icon',
          icon: function (topup) {
            switch (topup.paymentMethod) {
              case 'CASH': return {
                image: 'mdi-account-cash',
                tooltip: 'label.cashPayment'
              }
              case 'BANCONTACT': return {
                image: 't-bancontact',
                tooltip: 'label.bancontactPayment'
              }
            }
          }
        },
        {
          key: 'initiator',
          type: 'icon',
          icon: function (topup) {
            switch (topup.initiator) {
              case 'CASHIER': return {
                image: 'mdi-cash-register',
                tooltip: 'label.cashier'
              }
              case 'SCANNER': return {
                image: 'mdi-credit-card-scan',
                tooltip: 'label.scanner'
              }
              case 'USER': return {
                image: 'mdi-account',
                tooltip: 'label.user'
              }
            }
          }
        }
      ],"items":_vm.topupSearch,"search":true,"default-sort-property-key":"dateTime","item-key":"id","no-items-label":"message.noTopups"}})}
var staticRenderFns = []

export { render, staticRenderFns }