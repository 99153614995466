var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-web-app',{attrs:{"menu-items":[
        {
          icon: 'mdi-account-group',
          label: 'label.memberships',
          route: 'memberships'
        },
        {
          icon: 'mdi-cards',
          label: 'label.cards',
          route: 'cards'
        },
        {
          icon: 'mdi-credit-card-plus',
          label: 'label.topups',
          route: 'topups'
        },
        {
          icon: 'mdi-shopping',
          label: 'label.orders',
          route: 'orders'
        },
        {
          icon: 'mdi-gift',
          label: 'label.rewards',
          route: 'rewards'
        }
     ],"title":"label.user"}}),_c('card-edit-dialog'),_c('card-link-dialog'),_c('order-dialog'),_c('topup-dialog'),_c('welcome-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }